import React from "react";
import AboutBanner from "./components/about-us/AboutBanner";
import Layout from "./components/globle/Layout";
import BrandsLogo from "./components/Home/BrandsLogo";
import BussinessLook from "./components/Home/BussinessLook";
import CounterBox from "./components/Home/CounterBox";
import Testimonial from "./components/Home/Testimonial";

const AboutUs = () => {
  return (
    <>
      <Layout>
        <AboutBanner />
        <section className="counters-one pt-8">
          <div className="container">
            <div className="section-title text-center">
              <span className="section-title__tagline">About Us</span>
              <h2 className="section-title__title">Meta Devs</h2>
            </div>
            <p>
              Meta Devs is a dynamic and innovative company that specializes in
              a wide range of services, including Blockchain, NFT, and
              Cryptocurrency development, Game Design & Development, Web
              Development, Web & Mobile Design, NFT, AR/VR & Game Art, Video &
              Animation, Art & Illustration, and Ecommerce Development. <br />
              The Blockchain, NFT, and Cryptocurrency services provided by Meta
              Devs are tailored to meet the needs of clients seeking to
              establish or expand their presence in the ever-growing blockchain
              space. The company's experienced developers and designers work
              closely with clients to create unique solutions that are designed
              to maximize efficiency, security, and user-friendliness.
              <br />
              Meta Devs' Game Design & Development services are aimed at helping
              clients bring their gaming ideas to life. Whether you need a
              simple mobile game or a complex console game, the company's team
              of developers, designers, and animators have the expertise to
              bring your vision to life.
              <br />
              The Web Development services provided by Meta Devs are designed to
              help clients create highly-functional and responsive websites that
              are tailored to their specific needs. From custom web development
              to CMS-based solutions, the company's developers have the skills
              and experience needed to build websites that are optimized for
              performance, security, and scalability.
              <br />
              The Web & Mobile Design services provided by Meta Devs are focused
              on creating stunning designs that engage and captivate users. The
              company's designers have a keen eye for detail and are skilled in
              creating designs that are both aesthetically pleasing and
              highly-functional.
              <br />
              The NFT, AR/VR & Game Art services provided by Meta Devs are
              focused on creating stunning and immersive experiences that are
              sure to delight users. Whether you need custom 2D or 3D art
              assets, or complex AR/VR experiences, the company's designers and
              developers have the expertise to deliver.
              <br />
              The Video & Animation services provided by Meta Devs are designed
              to help clients create engaging and informative videos and
              animations that effectively convey their message. From explainer
              videos to animated product demos, the company's team of designers
              and animators have the skills and expertise needed to bring your
              ideas to life.
              <br />
              Finally, the Ecommerce Development services provided by Meta Devs
              are designed to help clients establish and grow their online
              retail presence. From custom e-commerce platforms to CMS-based
              solutions, the company's developers have the expertise needed to
              build e-commerce websites that are optimized for performance,
              security, and user-friendliness.
            </p>
          </div>
        </section>
        <div className="pt-3">
          <CounterBox />
        </div>
        <BussinessLook />
        {/* <Testimonial /> */}
        <BrandsLogo />
      </Layout>
    </>
  );
};

export default AboutUs;
