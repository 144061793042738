/** @format */

import React, { useEffect, useState } from "react";
import MobNav from "../Home/MobNav";
import Footer from "./compoment/Footer";
import Header from "./Header";
import Loader from "../loader";

const Layout = (props) => {
  const [loader, setLoader] = useState(true);
  const mainClass = props.mainClass != null ? props.mainClass : "";
  useEffect(() => {
    const timmer = setInterval(() => {
      setLoader(false);
    }, 3000);
    return () => {
      clearInterval(timmer);
    };
  }, []);
  return (
    <>
      <div className={`layout ${mainClass}`}>
        {loader ? <Loader /> : ""}
        <Header />
        {props.children}
        <Footer />
        <MobNav />
      </div>
    </>
  );
};

export default Layout;
