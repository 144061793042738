import React from "react";
import Metaverse from "../../../assest/images/resources/metaverse.png";
import Bitcoin from "../../../assest/images/resources/bitcoin.png";
import Layer from "../../../assest/images/resources/Layer-1.png";
import Nft from "../../../assest/images/resources/nft.png";
import Crypto from "../../../assest/images/resources/crypto.png";
import JoyStick from "../../../assest/images/resources/joystick-1.png";
import Computer from "../../../assest/images/resources/computer.png";
import { Link } from "react-router-dom";

const WeOffering = () => {
  return (
    <section className="services-one mt-5">
      <div className="services-one-bg"></div>
      <div className="container">
        <div className="section-title text-center">
          {/* <span className="section-title__tagline">Our services list</span> */}
          <h2 className="section-title__title">What we’re offering</h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4">
            <div
              className="services-one__single wow fadeInUp"
              data-wow-delay="100ms">
              <div className="services-one__icon">
                <span className="icon-color-sample">
                  <img src={Metaverse} alt="" />
                </span>
              </div>
              <h3 className="services-one__title">
                <Link to="website-design.html">Metaverse Development</Link>
              </h3>
              <p className="services-one__text">
                Virtual world games populated with Real Humans and AI driven
                characters.
              </p>
              <Link to="website-design.html" className="service-one__arrow">
                <span className="icon-right-arrow"></span>
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="services-one__single wow fadeInUp"
              data-wow-delay="100ms">
              <div className="services-one__icon">
                <span className="icon-color-sample">
                  <img src={Crypto} alt="" />
                </span>
              </div>
              <h3 className="services-one__title">
                <Link to="website-design.html">Blockchain Integration</Link>
              </h3>
              <p className="services-one__text">
                We focus on pragmatic approaches and solutions for using
                Blockchain for Integration in B2B ecosystems. We believe in a
                holistic approach where we guide you from ideation to
                realization of your Blockchain for Integration project.
              </p>
              <Link to="website-design.html" className="service-one__arrow">
                <span className="icon-right-arrow"></span>
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="services-one__single wow fadeInUp"
              data-wow-delay="100ms">
              <div className="services-one__icon">
                <span className="icon-front-end">
                  <img src={Layer} alt="" />
                </span>
              </div>
              <h3 className="services-one__title">
                <Link to="web-development.html">
                  Smart Contract Development
                </Link>
              </h3>
              <p className="services-one__text">
                Professional Blockchain smart contract development services and
                consultancy for smart contract architecture, design, and
                development
              </p>
              <Link to="web-development.html" className="service-one__arrow">
                <span className="icon-right-arrow"></span>
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4">
            <div
              className="services-one__single wow fadeInUp"
              data-wow-delay="100ms">
              <div className="services-one__icon">
                <span className="icon-online-shopping">
                  <img src={Nft} alt="" />
                </span>
              </div>
              <h3 className="services-one__title">
                <Link to="#">NFT Development</Link>
              </h3>
              <p className="services-one__text">
                We provide a comprehensive guide to develop your own
                non-fungible token and premium marketing services will enable
                you to over-achieve your potential, scale traffic, increase your
                ROI, and expand your crypto project’s awareness.
              </p>
              <Link to="#" className="service-one__arrow">
                <span className="icon-right-arrow"></span>
              </Link>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">
            <div
              className="services-one__single wow fadeInUp"
              data-wow-delay="100ms">
              <div className="services-one__icon">
                <span className="icon-online-shopping">
                  <img src={Crypto} alt="" />
                </span>
              </div>
              <h3 className="services-one__title">
                <Link to="#">Crypto Marketing Solutions</Link>
              </h3>
              <p className="services-one__text">
                Our team of professionals has expertly tailored our “Ready to
                Buy” premium marketing services. These services are dedicated to
                driving forward any marketing boundaries a crypto project might
                have encountered in its road to success.
              </p>
              <Link to="#" className="service-one__arrow">
                <span className="icon-right-arrow"></span>
              </Link>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">
            <div
              className="services-one__single wow fadeInUp"
              data-wow-delay="100ms">
              <div className="services-one__icon">
                <span className="icon-online-shopping">
                  <img src={JoyStick} alt="" />
                </span>
              </div>
              <h3 className="services-one__title">
                <Link to="#">Game Development</Link>
              </h3>
              <p className="services-one__text">
                Our certified gaming software development experts can create
                your video game project from scratch or revamp existing
                solutions with updates and integrations to stay in line with
                current gaming industry trends.
              </p>
              <Link to="#" className="service-one__arrow">
                <span className="icon-right-arrow"></span>
              </Link>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">
            <div
              className="services-one__single wow fadeInUp"
              data-wow-delay="100ms">
              <div className="services-one__icon">
                <span className="icon-online-shopping">
                  <img src={Computer} alt="" />
                </span>
              </div>
              <h3 className="services-one__title">
                <Link to="#">Web Development</Link>
              </h3>
              <p className="services-one__text">
                We offer a wide range of web development services with latest
                web development features, including up-to-date guidelines,
                search friendliness for search accessibility, compatibility with
                a screen reader for visually impaired, web semantics, and rich
                snippets.
              </p>
              <Link to="#" className="service-one__arrow">
                <span className="icon-right-arrow"></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeOffering;
