import React from "react";
import { Link } from "react-router-dom";
import nftImage4 from "../../../assest/images/resources/nft-img4.png";
import nftImage5 from "../../../assest/images/resources/nft-img5.png";
import nftImage6 from "../../../assest/images/resources/nft-img6.png";

const NftMarketPlace = () => {
  return (
    <section className="reasons-one mt-4">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="reasons-one__left">
              <div className="section-title text-left">
                <span className="section-title__tagline">
                  Our Recent Project
                </span>
                <h2 className="section-title__title">
                  NFT Marketplace Development
                </h2>
              </div>

              <p className="reasons-one__text-1">
                We help you launch your own NFT marketplace and attract the
                growing community of NFT users. NFT marketplaces built by us are
                feature-rich, decentralized, and facilitate tokenization of all
                kinds of assets, including artwork, gaming cards, software
                licenses, digital collectibles, and real-world assets.
              </p>
              <Link to="#" className="thm-btn">
                <span>Avocado NFT Marketplace</span>
              </Link>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="reasons-one__img-box">
              <div className="reasons-one-img-box-bg"></div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="reasons-one__img-one">
                    <img src={nftImage4} alt="" />
                    <div className="reasons-one__shape-1"></div>
                    <div className="reasons-one__shape-2"></div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="reasons-one__img-two">
                    <img src={nftImage5} alt="" />
                  </div>
                  <div
                    className="reasons-one__img-three
                                reasons-one__img-two">
                    <img src={nftImage6} alt="" />
                    <div className="reasons-one__shape-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NftMarketPlace;
