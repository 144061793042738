import React from "react";

const Map = () => {
  return (
    <section className="contact-page-google-map">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.726589982882!2d-75.65922738412182!3d39.72332677945177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c701c892855555%3A0x20a2b8882c1e4d73!2s2055%20Limestone%20Rd%20Suite%20200%20C%2C%20Wilmington%2C%20DE%2019808%2C%20USA!5e0!3m2!1sen!2s!4v1679910127927!5m2!1sen!2s"
              className="contact-page-google-map__box"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Map;
