import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../../../assest/images/resources/footer-logo.png";
const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="site-footer__top">
        <div className="site-footer-top-bg"></div>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div
                className="footer-widget__column
									footer-widget__about"
              >
                <div className="footer-widget__about-logo">
                  <Link to="index-2.html">
                    <img src={footerLogo} alt="" />
                  </Link>
                </div>
                <p className="footer-widget__about-text">
                  Welcome to the one of the best Gaming Solutions providers
                </p>
                <div className="footer-widget__about-social-list">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#" className="clr-fb">
                    <i
                      className="fab
												fa-facebook"
                    ></i>
                  </Link>
                  <Link to="#" className="clr-dri">
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                  <Link to="#" className="clr-ins">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div
                className="footer-widget__column
									footer-widget__explore clearfix"
              >
                <h3 className="footer-widget__title">Explore</h3>
                <ul
                  className="footer-widget__explore-list
										list-unstyled"
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="contact.html">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div
                className="footer-widget__column
									footer-widget__contact"
              >
                <h3 className="footer-widget__title">Contact</h3>
                <p className="footer-widget__contact-text">
                  2055 Limestone Road STE 200-C in Wilmington, DE 19808
                </p>
                <div className="footer-widget__contact-info">
                  <p>
                    <Link
                      to="tel:+1 302 289 3608"
                      className="footer-widget__contact-phone"
                    >
                      +1 302 289 3608
                    </Link>
                    <Link
                      to="mailto:contact@meta-devs.com"
                      className="footer-widget__contact-mail"
                    >
                      contact@meta-devs.us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div
                className="footer-widget__column
									footer-widget__newsletter"
              >
                <h3 className="footer-widget__title">Sign up for newsletter</h3>
                <form className="footer-widget__newsletter-form">
                  <div className="footer-widget__newsletter-input-box">
                    <input
                      type="email"
                      placeholder="Email Address"
                      name="email"
                    />
                    <button
                      type="submit"
                      className="footer-widget__newsletter-btn"
                    >
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </form>
                <div className="footer-widget__newsletter-bottom">
                  <div className="footer-widget__newsletter-bottom-icon">
                    <i className="fa fa-check"></i>
                  </div>
                  <div className="footer-widget__newsletter-bottom-text">
                    <p>I agree to all terms and policies</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="site-footer-bottom__inner">
                <p className="site-footer-bottom__copy-right">
                  © Copyright 2023 by <Link to="#">Meta Devs</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
