import React from "react";
import Bannerbox from "./components/Home/Bannerbox";
import Layout from "./components/globle/Layout";
import WebServices from "./components/Home/WebServices";
import CounterBox from "./components/Home/CounterBox";
import BussinessLook from "./components/Home/BussinessLook";
import WeMakes from "./components/Home/WeMakes";
import WeOffering from "./components/Home/WeOffering";
import Portfolio from "./components/Home/Portfolio";
import Testimonial from "./components/Home/Testimonial";
import Twobox from "./components/Home/Twobox";
import Videosec from "./components/Home/Videosec";
import Bestreasons from "./components/Home/Bestreasons";
import BlogsBox from "./components/Home/BlogsBox";
import BrandsLogo from "./components/Home/BrandsLogo";
import CtaBox from "./components/Home/CtaBox";
import Portfolio_item from "./components/portfolio/Portfolio_item";
import NftDesigns from "./components/Home/NftDesigns";
import NftMarketPlace from "./components/Home/NftMarketPlace";
import Gallery from "./components/Home/Gallery";

const Home = () => {
  return (
    <>
      <Layout>
        <Bannerbox />
        <WebServices />
        <CounterBox />
        <NftDesigns />
        <NftMarketPlace />
        <WeMakes />

        <BussinessLook />
        <Gallery />
        <WeOffering />
        {/* <Portfolio_item /> */}
        {/* <Testimonial /> */}
        {/* <Twobox />
        <Videosec /> */}
        <Bestreasons />
        {/* <BlogsBox /> */}
        <BrandsLogo />
        {/* <CtaBox /> */}
      </Layout>
    </>
  );
};

export default Home;
