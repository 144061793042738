import React from "react";
import { Button } from "react-bootstrap";
import Searchbox from "./WebServices";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFade, Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";

const Bannerbox = () => {
  return (
    <>
      <section className="main-slider">
        <div>
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            // loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper">
            <SwiperSlide>
              <div className="slide-box">
                <div className="image-layer bg-img-1"></div>

                <div className="image-layer-overlay"></div>
                <div className="main-slider-shape-1"></div>
                <div className="main-slider-shape-2"></div>
                <div className="main-slider-shape-3"></div>
                <div className="main-slider-shape-4"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-slider__content">
                        <p>welcome to Meta Devs</p>
                        <h2>
                          Building Games from <br /> Scratch to Finish
                        </h2>
                        <Link to="/" className="thm-btn">
                          <span>Free consultation</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-box">
                <div className="image-layer bg-img-2" s></div>

                <div className="image-layer-overlay"></div>
                <div className="main-slider-shape-1"></div>
                <div className="main-slider-shape-2"></div>
                <div className="main-slider-shape-3"></div>
                <div className="main-slider-shape-4"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-slider__content">
                        <p>welcome to Meta Devs</p>
                        <h2>
                          Entertainment <br />+ Gaming
                        </h2>
                        <Link to="contact.html" className="thm-btn">
                          <span>Free consultation</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide-box">
                <div className="image-layer bg-img-3"></div>

                <div className="image-layer-overlay"></div>
                <div className="main-slider-shape-1"></div>
                <div className="main-slider-shape-2"></div>
                <div className="main-slider-shape-3"></div>
                <div className="main-slider-shape-4"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-slider__content">
                        <p>welcome to Meta Devs</p>
                        <h2>
                          NFT Design & <br /> Render
                        </h2>
                        <Link to="/" className="thm-btn">
                          <span>Free consultation</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Bannerbox;
