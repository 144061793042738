import React from "react";
import Layout from "./components/globle/Layout";
import Contactsec1 from "./components/contact/Contactsec1";
import Formsec from "./components/contact/Formsec";
import Map from "./components/contact/Map";
import GetInTouch from "./components/contact/GetInTouch";

const Contact = () => {
  return (
    <>
      <Layout>
        <Contactsec1 />
        <Formsec />
        <Map />
        <GetInTouch />
      </Layout>
    </>
  );
};

export default Contact;
