import React from "react";
import CountUp from "react-countup";

const CounterBox = () => {
  return (
    <section className="counters-one">
      <div className="container">
        <ul className="counters-one__box list-unstyled">
          <li
            className="counter-one__single wow fadeInUp"
            data-wow-delay="100ms">
            <div className="counter-one__icon">
              <span className="icon-recommend"></span>
            </div>
            <h3 className="odometer" data-count="860">
              <CountUp end={860} duration={2} />
            </h3>
            <p className="counter-one__text">Projects completed</p>
          </li>
          <li
            className="counter-one__single wow fadeInUp"
            data-wow-delay="200ms">
            <div className="counter-one__icon">
              <span className="icon-recruit"></span>
            </div>
            <h3 className="odometer" data-count="50">
              <CountUp end={50} duration={2} />
            </h3>
            <p className="counter-one__text">Active clients</p>
          </li>
          <li
            className="counter-one__single wow fadeInUp"
            data-wow-delay="300ms">
            <div className="counter-one__icon">
              <span className="icon-coffee"></span>
            </div>
            <h3 className="odometer" data-count="93">
              <CountUp end={93} duration={2} />
            </h3>
            <p className="counter-one__text">Cups of coffee</p>
          </li>
          <li
            className="counter-one__single wow fadeInUp"
            data-wow-delay="400ms">
            <div className="counter-one__icon">
              <span className="icon-customer-review"></span>
            </div>
            <h3 className="odometer">
              <CountUp end={400} duration={2} />
            </h3>
            <p className="counter-one__text">Happy clients</p>
          </li>
          <li
            className="counter-one__shape wow fadeInUp"
            data-wow-delay="500ms"></li>
        </ul>
      </div>
    </section>
  );
};

export default CounterBox;
