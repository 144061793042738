import React from "react";
import galleryimg1 from "../../../assest/images/resources/galler-img1.png";
import galleryimg2 from "../../../assest/images/resources/galler-img2.png";
import galleryimg3 from "../../../assest/images/resources/galler-img3.png";
import galleryimg4 from "../../../assest/images/resources/galler-img4.png";
import galleryimg5 from "../../../assest/images/resources/galler-img5.webp";
import galleryimg6 from "../../../assest/images/resources/galler-img6.png";
import galleryimg7 from "../../../assest/images/resources/galler-img7.jpg";
import galleryimg8 from "../../../assest/images/resources/galler-img8.webp";
import galleryimg9 from "../../../assest/images/resources/galler-img9.png";
import galleryimg10 from "../../../assest/images/resources/galler-img10.webp";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Navigation } from "swiper";
const Gallery = () => {
  return (
    <section className="gallery mt-5">
      <h2 className="section-title__title text-center mb-3">Gallery</h2>
      <div className="">
        <div>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            navigation={true}
            loop={true}
            // pagination={{
            //   clickable: true,
            // }}
            breakpoints={{
              480: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 4,
              },
            }}
            modules={[Navigation]}
            className="mySwiper">
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg1} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg2} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg3} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg4} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg5} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg6} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg7} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg8} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg9} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={galleryimg10} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
