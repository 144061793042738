import React from "react";
import './Stand.css'
 import knightPic from "../../../../assest/images/resources/knight-side.png";
 

import {  StandData } from "../../../Data/Home_data";

const Stand = (props) => {
  return (
    <>
      <div className="container">
        <div className="stand">
          <h1>WHAT MAKES US STAND OUT</h1>
          <div className="row  standText_1">
            <div className="col-8 ">
              <div className="row standText_2">
                {StandData.map((item) => {
                  return (
                    <>
                      <div className="w-50 ">
                        <div className="d-flex  standText_3">
                          <img src={item.icon} />
                          <h3 className="fw-bold ms-3">{item.title}</h3>
                        </div>
                        <p>{item.description}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-2 ">
              <img src={knightPic} className="knightPic" alt="pic" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stand;
