import React from "react";
import getInTouchImage from "../../../assest/images/resources/get-in-touch-img.jpg";
import CountUp from "react-countup";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper";
import { Link } from "react-router-dom";
const GetInTouch = () => {
  return (
    <section className="get-in-touch">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div
              className="get-in-touch__img wow fadeInLeft"
              data-wow-duration="1500ms">
              <img src={getInTouchImage} alt="" />
              <div className="get-in-touch__img-shapes">
                <div className="get-in-touch__img-shape-1"></div>
                <div className="get-in-touch__img-shape-2"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="get-in-touch__right">
              <div className="section-title text-left">
                <span className="section-title__tagline">Contact with us</span>
                <h2 className="section-title__title">
                  Now Is The Time To Craft Brilliance Together
                </h2>
              </div>
              <div className="get-in-touch__locations">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="get-in-touch__locations-left">
                      <p className="get-in-touch__locations-text">
                        Let’s have a heart to heart talk. Now is the time to get
                        in touch and let us turn your game idea into an
                        exceptional masterpiece
                      </p>
                      <h3
                        className="odometer get-in-touch__locatins-count"
                        data-count="35600">
                        <CountUp end={35600} duration={2} />
                      </h3>
                      <h4 className="get-in-touch__locatins-count-text">
                        Projects has been completed
                      </h4>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="get-in-touch__locations-right">
                      <div className="">
                        <Swiper
                          loop={true}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination]}
                          className="mySwiper">
                          <SwiperSlide>
                            <div className="get-in-touch__locations-single">
                              <div className="get-in-touch__locations-top">
                                <div className="get-in-touch__locations-icon">
                                  <i className="icon-placeholder"></i>
                                </div>
                                <div className="get-in-touch__locations-title">
                                  <h4>Wilmington, DE 19808</h4>
                                </div>
                              </div>
                              <div className="get-in-touch__locations-bottom">
                                <p className="get-in-touch__locations-bottom-tagline">
                                  2055 Limestone Road STE 200-C in Wilmington,
                                  DE 19808
                                </p>
                                <h3>
                                  <Link
                                    to="mailto:contact@meta-devs.us"
                                    className="get-in-touch__locations-mail">
                                    contact@meta-devs.us
                                  </Link>
                                  <a
                                    to="tel:+1 302 289 3608"
                                    className="get-in-touch__locations-phone">
                                    +1 302 289 3608
                                  </a>
                                </h3>
                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="get-in-touch__locations-single">
                              <div className="get-in-touch__locations-top">
                                <div className="get-in-touch__locations-icon">
                                  <i className="icon-placeholder"></i>
                                </div>
                                <div className="get-in-touch__locations-title">
                                  <h4>Wilmington, DE 19808</h4>
                                </div>
                              </div>
                              <div className="get-in-touch__locations-bottom">
                                <p className="get-in-touch__locations-bottom-tagline">
                                  2055 Limestone Road STE 200-C in Wilmington,
                                  DE 19808
                                </p>
                                <h3>
                                  <Link
                                    to="mailto:contact@meta-devs.us"
                                    className="get-in-touch__locations-mail">
                                    contact@meta-devs.us
                                  </Link>
                                  <Link
                                    to="tel:+1 302 289 3608"
                                    className="get-in-touch__locations-phone">
                                    +1 302 289 3608
                                  </Link>
                                </h3>
                              </div>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
