import React from "react";

const Formsec = () => {
  const submitHandler = (e) => {
    e.preventDefault();

    // contactUsEmail();
  };
  return (
    <section className="contact-page">
      <div className="container">
        <div className="section-title text-center">
          <span className="section-title__tagline">Contact with us</span>
          <h2 className="section-title__title">Write us a message</h2>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="contact-page__form">
              <form
                onSubmit={submitHandler}
                className="comment-one__form contact-form-validated"
                novalidate="novalidate"
              >
                <div className="row">
                  <div className="col-xl-6">
                    <div className="comment-form__input-box">
                      <input type="text" placeholder="Your name" name="name" />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="comment-form__input-box">
                      <input
                        type="email"
                        placeholder="Email Address"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="comment-form__input-box">
                      <input
                        type="text"
                        placeholder="Phone number"
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="comment-form__input-box">
                      <input type="text" placeholder="Subject" name="Subject" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="comment-form__input-box">
                      <textarea
                        name="message"
                        placeholder="Write Message"
                      ></textarea>
                    </div>
                    <button type="submit" className="thm-btn faqs-contact__btn">
                      <span>Send a message</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Formsec;
