import React from "react";
import brandLogo1 from "../../../assest/images/resources/lama-logo-1.png";
import brandLogo2 from "../../../assest/images/resources/kaizilla-logo.png";
import brandLogo3 from "../../../assest/images/resources/world-logo.png";
import brandLogo4 from "../../../assest/images/resources/hamico-logo.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Navigation } from "swiper";
const BrandsLogo = () => {
  return (
    <section className="brand-one mt-4">
      <div className="container-fluid">
        <div>
          <Swiper
            slidesPerView={1}
            navigation={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              480: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 4,
              },
            }}
            modules={[Navigation, Pagination]}
            className="mySwiper">
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={brandLogo1} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={brandLogo2} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={brandLogo3} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <img src={brandLogo4} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default BrandsLogo;
