import React from "react";
import nftImage1 from "../../../assest/images/resources/nft-img1.png";
import nftImage2 from "../../../assest/images/resources/nft-img2.png";
import nftImage3 from "../../../assest/images/resources/nft-img3.png";

const NftDesigns = () => {
  return (
    <section className="reasons-one">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="reasons-one__left">
              <div className="section-title text-left">
                <span className="section-title__tagline">Our NFT Work</span>
                <h2 className="section-title__title">NFT Design</h2>
              </div>

              <p className="reasons-one__text-1">
                At Meta Devs NFT Design Company, we are committed to creating
                incredible art and animation that will rock the NFT market and
                bring significant benefits. Our team has extensive experience in
                creating content for NFT projects and is familiar with all the
                complexity of designing the most eye-catching art products.
              </p>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="reasons-one__img-box">
              <div className="reasons-one-img-box-bg"></div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="reasons-one__img-one">
                    <img src={nftImage1} alt="" />
                    <div className="reasons-one__shape-1"></div>
                    <div className="reasons-one__shape-2"></div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="reasons-one__img-two">
                    <img src={nftImage2} alt="" />
                  </div>
                  <div
                    className="reasons-one__img-three
                                reasons-one__img-two">
                    <img src={nftImage3} alt="" />
                    <div className="reasons-one__shape-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NftDesigns;
