import React from "react";

const Loader = (props) => {
  return (
    <>
      <div className="loading">
        <img src="/loader.svg" alt="" />
      </div>
    </>
  );
};
export default Loader;
