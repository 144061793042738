/** @format */

import portfolio1 from "../../assest/images/resources/portfolio-img-1.png";
import portfolio2 from "../../assest/images/resources/portfolio-img-2.png";
import portfolio3 from "../../assest/images/resources/portfolio-img-3.png";
import portfolio4 from "../../assest/images/resources/portfolio-img-4.png";
import portfolio5 from "../../assest/images/resources/portfolio-img-5.png";
import portfolio6 from "../../assest/images/resources/portfolio-img-6.png";
import portfolio7 from "../../assest/images/resources/portfolio-img-7.png";
import portfolio8 from "../../assest/images/resources/portfolio-img-8.png";
import portfolio9 from "../../assest/images/resources/portfolio-img-9.png";
import portfolio10 from "../../assest/images/resources/portfolio-img-10.png";
import icon1 from "../../assest/images/resources/handshake-1.svg";
import icon2 from "../../assest/images/resources/user-search.svg";
import icon3 from "../../assest/images/resources/world-security.svg";
import icon4 from "../../assest/images/resources/gamepad.svg";
import man from "../../assest/images/resources/faq-bg-knight.png";

export const PortflioItems = [
  {
    image: portfolio1,
    name: "Chicken Detective Police Officer Criminal Cases 3D",
    category: "Branding",
    url: "/",
  },
  {
    image: portfolio2,
    name: "DOOM Legend 2020 - Eternal MMORPG Idle Game",
    category: "Animation",
    url: "/",
  },
  {
    image: portfolio3,
    name: "Are You Ready Or Not",
    category: "Game Development",
    url: "/",
  },
  {
    image: portfolio4,
    name: "Scary Horror Clown Escape Game",
    category: "Animation",

    url: "/",
  },
  {
    image: portfolio5,
    name: "Siren Head Scary Horror Forest Story",
    category: "Branding",
    url: "/",
  },
  {
    image: portfolio6,
    name: "Spider Rope Hero: Super Stickman Robot Adventure",
    category: "Game Development",
    url: "/",
  },
  {
    image: portfolio7,
    name: "Teka Tarneeb Card Game (Early Access)",
    category: "Branding",
    url: "/",
  },
  {
    image: portfolio8,
    name: "Micro",
    category: "Game Development",
    url: "/",
  },
  {
    image: portfolio9,
    name: "Micro",
    category: "Branding",
    url: "/",
  },
];
export const StandData = [
  {
    id: 1,
    icon: icon1,
    title: "CUSTOMIZED FULL-CYCLE GAME DEVELOPMENT",
    description:
      "Our game art studio offers not only art production services but also fully-cycle game development production what enables us to cover the full scope of services of almost any project.",
  },
  {
    id: 2,
    icon: icon2,
    title: "TRANSPARENCY AND RELIABLE PARTNERSHIP ",
    description:
      "Thanks to an open dialogue, transparent communication and strict adherence to the wishes of the client, our team ensures complete satisfaction with the implemented projects and secures repeat business in 90% of cases.",
  },
  {
    id: 3,
    icon: icon3,
    title: "ADHERENCE TO THE BEST SECURITY PRACTICES",
    description:
      "Keep calm and don’t worry about data, system and infrastructure protection: our video game design company adheres to strict protection policy rules and constantly updates security systems in accordance with the latest innovations.",
  },
  {
    id: 4,
    icon: icon4,
    title: "RICH EXPERIENCE IN A VARIETY OF STYLES AND GENRES",
    description:
      "A team of great professional 140+ artists & developers with a wide experience in a variety of styles and genres in portfolios can cover the scope of almost any project.",
  },
  {
    id: 5,
    image: man,
  },
];

export const PortfolioCategories = [
  {
    category: "All",
    subCategory: [],
    data: [],
  },
  {
    category: "Web Designing",
    subCategory: [],
    data: [
      {
        image: "/assets/portfolio/webdesign/king.png",
        name: "King Ventures",
        url: "",
        subCategory: 1,
      },
    ],
  },
  // {
  //   category: "Blockchain",
  //   subCategory: ["Marketplace", "Minting DApps", "Smart Contracts"],
  //   data: [
  //     {
  //       image: portfolio1,
  //       name: "Test 1",
  //       url: "",
  //       subCategory: 1,
  //     },
  //     {
  //       image: portfolio2,
  //       name: "Test 2",
  //       url: "",
  //       subCategory: 1,
  //     },
  //     {
  //       image: portfolio3,
  //       name: "Test 3",
  //       url: "",
  //       subCategory: 2,
  //     },
  //     {
  //       image: portfolio4,
  //       name: "Test 4",
  //       url: "",
  //       subCategory: 3,
  //     },
  //   ],
  // },
  {
    category: "NFT's",
    subCategory: [],
    data: [
      {
        image: "/assets/portfolio/nft/ANIMEGIRLS.png",
        name: "ANIMEGIRLS",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/BILLY.png",
        name: "BILLY",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/CULEK.png",
        name: "CULEK",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/DOODLE.png",
        name: "DOODLE",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/FROSTY.jpg",
        name: "FROSTY",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/FUNKSPONGE.png",
        name: "FUNK SPONGE",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/GLOOMBOY.png",
        name: "GLOOMBOY",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/HOT-DOG.png",
        name: "Hot Dog",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/MetaDragon.png",
        name: "Meta Dragon",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/Metalama.png",
        name: "Metalama",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/ORANGE.png",
        name: "Orange",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/PIKABOO.png",
        name: "Pikaboo",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/PISSMACKY.png",
        name: "Piss Macky",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/PLANETS.png",
        name: "Planets",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/Rainbella.png",
        name: "Rainbella",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/SHEERRABIT.png",
        name: "Sheer Rabit",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/SKULLSMASHER.png",
        name: "Skull Smasher",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/STONEBOB.png",
        name: "Stone Bob",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/SlyKAI.png",
        name: "Sly KAI",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/bunnyjump.png",
        name: "Bunny Jump",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/hedgehog.png",
        name: "Hedgehog",
        url: "",
        subCategory: 0,
      },
      {
        image: "/assets/portfolio/nft/popstick.png",
        name: "Popstick",
        url: "",
        subCategory: 0,
      },
    ],
  },
  {
    category: "Web Development",
    subCategory: [
      "CRM",
      "ERP",
      "DApp",
      // "Portfolio Site",
      // "Personal Site",
    ],
    data: [
      {
        image: "/assets/portfolio/web/hgp.png",
        name: "CRM for eCommerce Website",
        url: "https://homegrownpros.io/",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/web/erp.png",
        name: "ERP System",
        url: "https://my.spiro365.com/register",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/web/nft.png",
        name: "Avocado NFT Marketplace",
        url: "https://avonftmarket.io/",
        subCategory: 3,
      },
      {
        image: "/assets/portfolio/web/kaizilla.png",
        name: "Kaizilla DApp",
        url: "https://kaizilla.io/",
        subCategory: 3,
      },
      {
        image: "/assets/portfolio/web/ymeme.png",
        name: "yMeme DApp",
        url: "https://stake.ymeme.org/",
        subCategory: 3,
      },
    ],
  },
  {
    category: "Game Development",
    subCategory: ["Mobile Games", "PC Games", "WebGL"],
    data: [
      {
        image: "/assets/portfolio/gamedev/mobgame/tarneebMaster.webp",
        name: "Tarneeb Masters",
        url: "https://play.google.com/store/apps/details?id=com.yallaplay.tarneebmasters&pli=1",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/Markadracing.jpg",
        name: "Markad Racing",
        url: "https://play.google.com/store/apps/details?id=com.markadracing.camel",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/BubbleShooter.jpg",
        name: "Bubble Shooter",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/BusSImulator.jpg",
        name: "Bus Simulator",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/CarSimulator.jpg",
        name: "Car Simulator",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/ClashRoyale.jpg",
        name: "Clash Royale",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/FireHead.png",
        name: "Fire Head",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/IceScreamMan.png",
        name: "Ice Scream Man",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/NeighbourGame.jpg",
        name: "Neighbour Game",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/OilTruck.jpg",
        name: "Oil Truck",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/SpidermanRopeHero.png",
        name: "Spiderman Rope Hero",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/mobgame/Zombie.jpg",
        name: "Zombie",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/gamedev/pcgame/kaizilla.jpg",
        name: "Kaizilla",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/gamedev/pcgame/WorldOfHades.jpg",
        name: "World Of Hades",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/gamedev/webgl/BunnyJump.png",
        name: "Bunny Jump",
        url: "",
        subCategory: 3,
      },
      {
        image: "/assets/portfolio/gamedev/webgl/MetaDragon.jpg",
        name: "Meta Dragon",
        url: "",
        subCategory: 3,
      },
      {
        image: "/assets/portfolio/gamedev/webgl/metalama.jpg",
        name: "Metalama",
        url: "",
        subCategory: 3,
      },
    ],
  },
  {
    category: "2D Art",
    subCategory: ["UI/UX", "Environments"],
    data: [
      {
        image: "/assets/portfolio/2dart/ui/Animegame.jpg",
        name: "Anime Game",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/BunnyJump.jpg",
        name: "Bunny Jump",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/busuiportfolio.jpg",
        name: "Bussi Portfolio",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/CarSimulation.jpg",
        name: "Car Simulation",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/Clash.jpg",
        name: "Clash",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/Firehead.jpg",
        name: "Firehead",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/Piratebubbleshooter.jpg",
        name: "Pirate Bubble Shooter",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/scretneighborportfolio.jpg",
        name: "Secret Neighbor Portfolio",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/Swatui.jpg",
        name: "Swat UI",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/swordwarriorportfolio.jpg",
        name: "Sword Warrior Portfolio",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/TheGanji.jpg",
        name: "The Ganji",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/thehunterportfolio.jpg",
        name: "The Hunter Portfolio",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/ui/TruckraceUI.jpg",
        name: "Truck Race UI",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/2dart/environments/1.jpg",
        name: "Jungle 1",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/2.jpg",
        name: "Jungle 2",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/3.jpg",
        name: "Jungle 3",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/5.jpg",
        name: "Jungle 4",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/6.png",
        name: "Jungle 5",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/7.png",
        name: "Jungle 6",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/8.png",
        name: "Jungle 7",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/9.png",
        name: "Jungle 8",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/2dart/environments/Snowballgame.jpg",
        name: "Snowballgame",
        url: "",
        subCategory: 2,
      },
    ],
  },
  {
    category: "3D Art",
    subCategory: ["3D Environment"],
    data: [
      {
        image: "/assets/portfolio/3dart/environments/Bunnyjump.png",
        name: "Bunny Jump",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/3dart/environments/BusSimulator.jpg",
        name: "Bus Simulator",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/3dart/environments/ClashRoyale.jpg",
        name: "Clash Royale",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/3dart/environments/RampGame.jpg",
        name: "Ramp Game",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/3dart/environments/RopeHero.png",
        name: "Rope Hero",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/3dart/environments/ScaryJungle.jpg",
        name: "Scary Jungle",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/3dart/environments/Scaryneighbour.jpg",
        name: "Scary Neighbour",
        url: "",
        subCategory: 1,
      },
      {
        image: "/assets/portfolio/3dart/environments/Truckrace.jpg",
        name: "Truck Race",
        url: "",
        subCategory: 1,
      },
    ],
  },
  {
    category: "Characters",
    subCategory: ["2D Characters", "3D Characters"],
    data: [
      // // 2D Characters
      // {
      //   image: "/assets/portfolio/3dart/characters/Bomba.png",
      //   name: "Bomba",
      //   url: "",
      //   subCategory: 2,
      // },

      // 3D Characters
      {
        image: "/assets/portfolio/3dart/characters/Bomba.png",
        name: "Bomba",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/3dart/characters/Boy.png",
        name: "Boy",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/3dart/characters/doctor.png",
        name: "Doctor",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/3dart/characters/kid2.png",
        name: "Kids Characters",
        url: "",
        subCategory: 2,
      },
      {
        image: "/assets/portfolio/3dart/characters/Waving.png",
        name: "Waving",
        url: "",
        subCategory: 2,
      },
    ],
  },
  // {
  //   category: "Environments",
  //   subCategory: ["2D Environments", "3D Environments"],
  //   data: [],
  // },
  // {
  //   category: "Metaverse",
  //   subCategory: ["VR Environments", "VR Assets"],
  //   data: [],
  // },
];
