import React from "react";

const Contactsec1 = () => {
  return (
    <section className="page-header contact-home">
      <div className="page-header-shape-1"></div>
      <div className="page-header-shape-2"></div>
      <div className="container">
        <div className="page-header__inner">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="index-2.html">META DEVS</a>
            </li>
            <li>
              <span>.</span>
            </li>
            <li>CONTACT</li>
          </ul>
          <h2>Contact</h2>
        </div>
      </div>
    </section>
  );
};

export default Contactsec1;
