/** @format */

import React, { useEffect, useState } from "react";
import { PortflioItems, PortfolioCategories } from "../../Data/Home_data";
import FilterMenu from "./FilterMenu";
import Single_Portfolio from "./Single_Portfolio";
import Loader from "../loader";

// Get Unique Category from Array
let allCatvlaues = [
  ...new Set(
    PortfolioCategories.map((curElem) => {
      return {
        category: curElem.category,
        data: curElem.data,
      };
    })
  ),
];
allCatvlaues = allCatvlaues
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);
// const allCatvlaues = [PortflioItems.map((curElem) => curElem.category), "All"];
// console.log(allCatvlaues);
const Portfolio_item = () => {
  const [catItem, setCatItem] = useState(allCatvlaues);
  const [item, setItem] = useState(allCatvlaues);
  const [isList, setIsList] = useState(false);
  const [loader, setLoader] = useState(true);
  const [categoryId, setCategoryId] = useState(0);
  const DropDownToggle = () => {
    setIsList(!isList);
  };
  const [isLists, setIsLists] = useState(false);
  const DropDownToggles = () => {
    setIsLists(!isLists);
    // console.log("test");
  };

  const findImagesBySubCat = (catId, subCatId) => {
    const val = PortfolioCategories[catId];
    const imageDetails = val.data?.filter(
      (curElem) => curElem.subCategory === parseFloat(subCatId) + 1
    );
    return [
      {
        category: val.category,
        data: imageDetails,
      },
    ];
  };

  const finImagesByCat = (catId) => {
    const val = PortfolioCategories[catId];
    const sort = val?.data
      ?.map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    return [
      {
        category: val.category,
        data: sort,
      },
    ];
  };

  const filterItem = (
    catId,
    subCatId,
    subCat = true,
    searchByMainCat = false
  ) => {
    setLoader(true);
    setCategoryId(catId);
    if (catId === 0) {
      let shuffle = allCatvlaues
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      setItem(shuffle);
      setLoader(false);
      return;
    }
    console.log(catId && searchByMainCat);
    if (catId && searchByMainCat) {
      setItem(finImagesByCat(catId));
      setLoader(false);
      return;
    }
    if (!subCat && catId !== 0) {
      // console.log([PortfolioCategories[catId]]);
      setItem([PortfolioCategories[catId]]);
      setLoader(false);
      return;
    }
    // console.log(findImagesBySubCat(catId, subCatId));
    setItem(findImagesBySubCat(catId, subCatId));
    setLoader(false);
  };

  useEffect(() => {
    setLoader(false);
  }, []);
  return (
    <>
      {loader ? <Loader /> : ""}
      <div
        className={isList ? "select is-open" : "select"}
        onClick={DropDownToggle}
      >
        {/* <ul
          className="portfolio-filter custom-tab style1 post-filter
								has-dynamic-filters-counter list-unstyled mt-5 portfolio-new"
        >
          {PortfolioCategories?.map((val, index) => {
            return (
              <li key={index}>
                {val.category}
                <div className="dropdown">
                  {val.subCategory?.length
                    ? val.subCategory?.map((sub, i) => {
                        return <span key={i}>{sub}</span>;
                      })
                    : ""}
                </div>
              </li>
            );
          })}
        </ul> */}
        {/* <span className="placeholder">Select category</span> */}
        <ul
          className="portfolio-filter custom-tab style1 post-filter
								has-dynamic-filters-counter list-unstyled mt-5 portfolio-new"
        >
          {/* <li className="filter__item">Category</li> */}
          <FilterMenu
            filterItem={filterItem}
            catItem={PortfolioCategories}
            catId={categoryId}
            // onClick={DropDownToggles}
            // className={isLists ? "select is-open" : "select"}
          />
          <input type="hidden" name="changemetoo" />
        </ul>
        <input type="hidden" name="changemetoo" />
      </div>
      {/* <!-- Filter --> */}
      {/* <div className="select">
				<ul className="filter">
					<FilterMenu filterItem={filterItem} catItem={catItem} />
					 <input type="hidden" name="changemetoo" />
				</ul>
			</div> */}
      {/* <!-- Gallery --> */}
      <Single_Portfolio item={item} />
      {/* <!-- /Gallery --> */}
    </>
  );
};

export default Portfolio_item;
