/** @format */

import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Carousel, { Modal, ModalGateway } from "react-images";
import { FaLink } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";

const Portfolio_Item = ({ url, image, name, category, key, toggleModal }) => {
  return (
    <div
      className="col-xl-4 col-lg-6 col-md-6 filter-item bra illus web "
      key={key}
    >
      <div className="portfolio-one__single">
        <div className="portfolio-one__img">
          <div className="gallery-grid__image-wrap">
            <img
              className="gallery-grid__image cover lazyload"
              src={image}
              alt=""
            />
          </div>

          <div className="portfolio-one__experience">
            <div className="portfolio-one__web-design">
              <p className="portfolio-one__web-design-title">{name}</p>
              <div className="icon">
                {url ? (
                  <a href={url} target="_blank">
                    {" "}
                    <div className="icon-bg">
                      <FaLink />{" "}
                    </div>
                  </a>
                ) : (
                  ""
                )}
                <div className="icon-bg">
                  <AiFillEye onClick={(e) => toggleModal(e, image)} />
                </div>
              </div>
            </div>
            <div className="portfolio-one__fimlor">
              <p className="portfolio-one__fimlor-title">
                <Link to="/">{category}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Single_Portfolio = ({ item }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const toggleModal = (e, image = "") => {
    setModalIsOpen(!modalIsOpen);
    setPreviewImage(image);
  };
  return (
    <>
      <div className="container">
        <div className="row filter-layout masonary-layout gallery-grid js-masonry js-filter-container">
          {/* <!-- Items --> */}
          {item?.map((list) => {
            return list.data?.map((val, i) => {
              return (
                <Portfolio_Item
                  key={i}
                  image={val.image}
                  name={val.name}
                  category={list?.category}
                  url={val.url}
                  toggleModal={toggleModal}
                  setPreviewImage={setPreviewImage}
                />
              );
            });
          })}
          <ModalGateway>
            {/* Modal Image */}
            {modalIsOpen ? (
              <Modal onClose={toggleModal}>
                <Carousel
                  views={[
                    {
                      source: previewImage,
                    },
                  ]}
                />
              </Modal>
            ) : (
              ""
            )}
          </ModalGateway>
        </div>
      </div>
    </>
  );
};
export default Single_Portfolio;
