import React from "react";
import { Link } from "react-router-dom";

const AboutBanner = () => {
  return (
    <section className="page-header blog-banner">
      <div className="page-header-shape-1"></div>
      <div className="page-header-shape-2"></div>
      <div className="container">
        <div className="page-header__inner">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link to="/">META DEVS</Link>
            </li>
            <li>
              <span>.</span>
            </li>
            <li>ABOUT</li>
          </ul>
          <h2>ABOUT</h2>
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;
