import React from "react";
import { Button, Form, NavLink } from "react-bootstrap";
import CountUp from "react-countup";
// import Odometer from "react-odometerjs";
import webServicesImage from "../../../assest/images/resources/welcome-one-img-1.png";

const WebServices = () => {
  return (
    <div className="">
      <section className="welcome-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div
                className="welcome-one__left wow fadeInLeft"
                data-wow-duration="1500ms">
                <div className="welcome-one__img-box">
                  <div className="welcome-one__img">
                    <img src={webServicesImage} alt="" />
                    <div className="welcome-one__shape-1"></div>
                    <div className="welcome-one__shape-2"></div>
                  </div>
                  <div className="welcome-one__trusted">
                    <p>Trusted by</p>
                    <h2 className="odometer">
                      <CountUp end={4890} duration={2} />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="welcome-one__right">
                <div className="section-title text-left">
                  <span className="section-title__tagline">About company</span>
                  <h2 className="section-title__title">
                    We provide the best Gaming experience
                  </h2>
                </div>
                <div className="welcome-one__solutions">
                  <div className="welcome-one__solutions-single">
                    <div className="welcome-one__solutions-icon">
                      <span className="icon-tick"></span>
                    </div>
                    <div className="welcome-one__solutions-text-box">
                      <p>
                        Metaverse <br /> Development
                      </p>
                    </div>
                  </div>
                  <div className="welcome-one__solutions-single">
                    <div className="welcome-one__solutions-icon">
                      <span className="icon-tick"></span>
                    </div>
                    <div className="welcome-one__solutions-text-box">
                      <p>
                        Game <br />
                        Development
                      </p>
                    </div>
                  </div>
                </div>
                <p className="welcome-one__right-text-1">
                  We provide the best services in Metaverse Development and Game
                  Development. We develop games for multiple platforms like
                  Android, iOS, AR, VR, Windows, Mac and WebGL.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebServices;
