import React from "react";
import { Accordion } from "react-bootstrap";
import businessImage from "../../../assest/images/resources/we-change-right-img-1.jpg";

const BussinessLook = () => {
  return (
    <section className="we-change">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="we-change__left-faqs">
              <div className="section-title text-left">
                <span className="section-title__tagline">
                  Frequently asked questions
                </span>
                <h2 className="section-title__title">
                  Transforming Your Vision Into An Epic Creation
                </h2>
              </div>

              <div className="we-change__faqs">
                <Accordion
                  className="accrodion faq-one-accrodion"
                  defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="accrodion-title">
                      <h4>Blockchain Consultation</h4>
                    </Accordion.Header>
                    <Accordion.Body className="accrodion-content">
                      <div className="inner">
                        Blockchain is the evolution of technology. We provide
                        blockchain development services that are aimed at
                        providing practical solutions that will help your
                        company become efficient, flexible, and lucrative. Our
                        skilled blockchain developers will solve your
                        difficulties while ensuring that all your customized
                        requirement have been met
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="accrodion-title">
                      <h4>Gamification</h4>
                    </Accordion.Header>
                    <Accordion.Body className="accrodion-content">
                      <div className="inner">
                        We have a team of expert game developers, mastering
                        Unity Engine. We integrate blockchain in our customized
                        games, due to the characteristics that it adheres such
                        as transparency, authenticity, and compatibility.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="accrodion-title">
                      <h4>Customized NFT Marketplace</h4>
                    </Accordion.Header>
                    <Accordion.Body className="accrodion-content">
                      <div className="inner">
                        We create customized NFT marketplaces, which are
                        basically a decentralized platforms. We create such
                        platforms according to your business requirements, where
                        you can create, buy, sell and store these NFTs.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="we-change__right">
              <div className="we-change__right-img">
                <img src={businessImage} alt="" />
                <div className="we-change__agency">
                  <div className="we-change__agency-icon">
                    <span className="icon-development"></span>
                  </div>
                  <div className="we-change__agency-text">
                    <h3>
                      Our agency is one of the most
                      <br /> successful agency.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BussinessLook;
