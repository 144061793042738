import React from "react";
import { Link } from "react-router-dom";

const MobNav = () => {
  return (
    <div>
      <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>

        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler"></span>

          <div className="logo-box">
            <Link to="index-2.html" aria-label="logo image">
              <img src="assets/images/resources/logo.png" width="155" alt="" />
            </Link>
          </div>

          <div className="mobile-nav__container"></div>
          <div className="main-menu-wrapper__main-menu">
            <Link to="#" className="mobile-nav__toggler">
              <span></span>
            </Link>
            <ul className="main-menu__list">
              <li className="dropdown">
                <Link to="index-2.html">Home</Link>
              </li>

              <li className="dropdown">
                <Link to="/portfolio">Portfolio</Link>
              </li>

              <li className="dropdown">
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <Link to="#" className="fab fa-twitter"></Link>
              <Link to="#" className="fab fa-facebook-square"></Link>
              <Link to="#" className="fab fa-pinterest-p"></Link>
              <Link to="#" className="fab fa-instagram"></Link>
            </div>
          </div>
        </div>
      </div>

      <div className="search-popup">
        <div className="search-popup__overlay search-toggler"></div>
        <div className="search-popup__content">
          <form action="#">
            <label for="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button type="submit" aria-label="search submit" className="thm-btn">
              <i className="icon-magnifying-glass"></i>
            </button>
          </form>
        </div>
      </div>

      <Link to="#" data-target="html" className="scroll-to-target scroll-to-top">
        <i className="fa fa-angle-up"></i>
      </Link>
    </div>
  );
};

export default MobNav;
