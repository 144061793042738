import React from "react";
import Layout from "./components/globle/Layout";
import PortfolioBanner from "./components/portfolio/PortfolioBanner";
import Portfolio from "./components/Home/Portfolio";
import Portfolio_item from "./components/portfolio/Portfolio_item";
import Stand from "./components/portfolio/Stand/Stand";
import PortfolioForm from "./components/portfolio/Form/PortfolioForm";

const PortfolioPage = () => {
  return (
    <>
      <Layout>
        <PortfolioBanner />
        <div className="portfolio-page">
          <div className="port_Text">
            <h1>our porfolio </h1>
            <p>
              We have collected a large number of casual art examples created by
              our artists – check out this casual game art portfolio to see our
              experience!
            </p>
          </div>
          {/* <Portfolio /> */}
          <Portfolio_item />
          <Stand />

          {/* <Stand StandData={Data_4} /> */}
          <PortfolioForm />
        </div>
      </Layout>
    </>
  );
};

export default PortfolioPage;
