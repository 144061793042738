import React from "react";
import { Link } from "react-router-dom";
import reasonImage1 from "../../../assest/images/resources/reasons-one-img-1.jpg";
import reasonImage2 from "../../../assest/images/resources/reasons-one-img-2.jpg";
import reasonImage3 from "../../../assest/images/resources/reasons-one-img-3.jpg";

const Bestreasons = () => {
  return (
    <section className="reasons-one mt-3">
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <div className="reasons-one__left">
              <div className="section-title text-left">
                <span className="section-title__tagline">Our benefits</span>
                <h2 className="section-title__title">
                  Reasons why we are best
                </h2>
              </div>
              <ul className="list-unstyled reasons-one__icon-box">
                <li>
                  <span className="icon-training"></span>
                  <p className="reasons-one__text">The best user interfaces</p>
                </li>
                <li>
                  <span className="icon-strategy"></span>
                  <p className="reasons-one__text">
                    Quick smooth web development
                  </p>
                </li>
              </ul>
              <p className="reasons-one__text-1">
                We believe in quality and that's what makes a difference for
                your growing business in this fast paced market. We provide best
                quality products with on going maintenance to support your
                business.
              </p>
              <Link to="#" className="thm-btn">
                <span>Discover more</span>
              </Link>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="reasons-one__img-box">
              <div className="reasons-one-img-box-bg"></div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="reasons-one__img-one">
                    <img src={reasonImage1} alt="" />
                    <div className="reasons-one__shape-1"></div>
                    <div className="reasons-one__shape-2"></div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="reasons-one__img-two">
                    <img src={reasonImage3} alt="" />
                  </div>
                  <div
                    className="reasons-one__img-three
                                reasons-one__img-two">
                    <img src={reasonImage2} alt="" />
                    <div className="reasons-one__shape-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bestreasons;
