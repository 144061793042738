/** @format */

import React, { useState } from "react";

const FilterMenu = ({ catItem, filterItem, catId }) => {
  const [active, setActive] = useState("");
  const changeHandler = (curentItem, subCatId, subCat, searchByMainCat) => {
    filterItem(curentItem, subCatId, subCat, searchByMainCat);
    setActive(curentItem);
    // if (curentItem == active) {
    // 	console.log("hello");
    // }
  };

  return (
    <>
      {catItem.map((curentItem, index) => (
        <li
          key={index}
          className={`prevent-select ${catId === index ? "active" : ""}`}
          // onClick={
          //   index === 0 || curentItem.subCategory?.length === 0
          //     ? () =>
          //         changeHandler(
          //           index,
          //           index,
          //           curentItem.subCategory?.length == 0 ? false : true
          //         )
          //     : () => console.log(index)
          // }
        >
          <div
            className="main-cat"
            onClick={() =>
              changeHandler(
                index,
                index,
                curentItem.subCategory?.length == 0 ? false : true,
                index === 0 ? false : true
              )
            }
          >
            {curentItem.category}
          </div>
          {curentItem.subCategory?.length ? (
            <div className="dropdown sub-cat">
              {curentItem.subCategory?.map((sub, i) => {
                return (
                  <span
                    key={i}
                    onClick={() => changeHandler(index, i, true, false)}
                  >
                    {sub}
                  </span>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </li>
      ))}
    </>
  );
};

export default FilterMenu;
