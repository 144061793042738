/** @format */

import React, { useState } from "react";
import { Button, Navbar, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../../assest/images/resources/logo.png";
import Phone from "../../../assest/images/phone.svg";
import Close_icon from "../../../assest/images/close-icon.png";
import Search_icon from "../../../assest/images/search.svg";
import Profile from "../../../assest/images/profile.svg";
import Menu_img from "../../../assest/images/menu-img.svg";
import { Nav } from "react-bootstrap";
import Nav_Links from "./compoment/Nav_Links";

const Header = () => {
  const [toggleNav, setToggleNav] = useState(false);

  const toggleMenu = () => {
    setToggleNav(!toggleNav);
  };
  return (
    <div>
      <header className="main-header clearfix">
        <nav className="main-menu clearfix">
          <div className="main-menu-wrapper clearfix">
            <div className="main-menu-wrapper__left clearfix">
              <div className="main-menu-wrapper__logo">
                <Link className="p-0" to="/">
                  <img src={Logo} alt="" />
                </Link>
                {/* <Link to="index-2.html"></Link> */}
              </div>

              <div className="main-menu-wrapper__social d-none d-lg-flex">
                <Link to="#">
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link to="#" className="clr-fb">
                  <i
                    className="fab
										fa-facebook"
                  ></i>
                </Link>
                <Link to="#" className="clr-dri">
                  <i
                    className="fab
										fa-pinterest-p"
                  ></i>
                </Link>
                <Link to="#" className="clr-ins">
                  <i
                    className="fab
										fa-instagram"
                  ></i>
                </Link>
              </div>
            </div>

            <div className="main-menu-wrapper__main-menu ">
              <ul className="main-menu__list d-none d-lg-flex">
                <li className="dropdown">
                  <Link to="/">Home</Link>
                </li>

                <li className="dropdown">
                  <Link to="/portfolio">Portfolio</Link>
                </li>

                <li className="dropdown">
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
              <Link
                // disabled={toggleNav}
                onClick={() => {
                  toggleMenu();
                }}
                to="#"
                className="mobile-nav__toggler d-block d-lg-none"
              >
                <span></span>
              </Link>
              <div className={`menucontainer ${toggleNav ? "show" : null}`}>
                <div className="main-menu-wrapper__logo text-start p-3 d-block d-lg-none">
                  <Link className="p-0" to="/">
                    <img src={Logo} alt="" />
                  </Link>
                  {/* <Link to="index-2.html"></Link> */}
                </div>
                <ul className="main-menu__list">
                  <li className="dropdown">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="dropdown">
                    <Link to="/portfolio">Portfolio</Link>
                  </li>

                  <li className="dropdown">
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
                <div className="mobile-nav__text d-block d-lg-none text-start pt-4 pb-3 px-3">
                  Welcome to the one of the best Gaming Solutions providers{" "}
                </div>
                <div className="main-menu-wrapper__social d-flex d-lg-none">
                  <Link to="#">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  <Link to="#" className="clr-fb">
                    <i
                      className="fab
										fa-facebook"
                    ></i>
                  </Link>
                  <Link to="#" className="clr-dri">
                    <i
                      className="fab
										fa-pinterest-p"
                    ></i>
                  </Link>
                  <Link to="#" className="clr-ins">
                    <i
                      className="fab
										fa-instagram"
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="main-menu-wrapper__right">
              <div className="main-menu-wrapper__right-contact-box">
                <div className="main-menu-wrapper__right-contact-icon">
                  <span className="icon-phone-call"></span>
                </div>
                <div className="main-menu-wrapper__right-contact-number">
                  <Link to="tel:+1 302 289 3608">+1 302 289 3608</Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
    </div>
  );
};

export default Header;
