import React from "react";
import './Form.css'

const PortfolioForm = () => {
  return (
    <>
      <section className="bgImg">
        <div className="form1">
          <div className="formText">
            <h2>LET'S TALK ABOUT YOUR PROJECT</h2>
            <p>
              We are ready to discuss the details of your project and answer any
              of your questions: just fill out the form below.
            </p>
          </div>
          <form>
            <div className="text">
              <div className="form__group">
                <input
                  type="name"
                  id="name"
                  className="form__field"
                  placeholder="Enter your name"
                />
                <label for="email" className="form__label">
                  Name
                </label>
              </div>
              <div className="form__group">
                <input
                  type="email"
                  id="email"
                  className="form__field"
                  placeholder="Your Email"
                />
                <label for="email" className="form__label">
                  Email
                </label>
              </div>
              <div className="form__group">
                <input
                  type="text"
                  id="company"
                  className="form__field"
                  placeholder="Your company name"
                />
                <label for="company" className="form__label">
                  Company
                </label>
              </div>

              <div className="form__group">
                <textarea
                  id="message"
                  className="form__field"
                  placeholder="Your Message"
                  rows="2"
                ></textarea>
                <label for="message" className="form__label">
                  Your Message
                </label>
              </div>
            </div>
            <button className="formBtn">CONTACT US</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default PortfolioForm;
