import React from "react";
import { Line } from "rc-progress";
import personImg from "../../../assest/images/resources/person-img-1.png";
const WeMakes = () => {
  return (
    <section className="we-make my-3">
      <div className="we-make-bg"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6">
            <div className="we-make__left">
              <div className="section-title text-left">
                {/* <span className="section-title__tagline">
                  Corporate business theme
                </span> */}
                <h2 className="section-title__title">
                  We make the quality design & developments
                </h2>
              </div>
              <div className="we-make__person">
                <div className="we-make__person-img">
                  <img src={personImg} alt="" />
                </div>
                <div className="we-make__person-text">
                  <p>John Smith</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="we-make__right">
              <div className="we-make__progress">
                <div className="we-make__progress-single">
                  <h4 className="we-make__progress-title">Game design</h4>

                  <Line
                    percent={100}
                    strokeWidth={3.3}
                    trailWidth={3.3}
                    trailColor="#323236"
                    strokeColor="#fc653c"
                  />
                </div>
                <div className="we-make__progress-single">
                  <h4 className="we-make__progress-title">Game development</h4>

                  <Line
                    percent={100}
                    strokeWidth={3.3}
                    trailWidth={3.3}
                    trailColor="#323236"
                    strokeColor="#fc653c"
                  />
                </div>
                <div className="we-make__progress-single">
                  <h4 className="we-make__progress-title">
                    Blockchain development
                  </h4>

                  <Line
                    percent={100}
                    strokeWidth={3.3}
                    trailWidth={3.3}
                    trailColor="#323236"
                    strokeColor="#fc653c"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeMakes;
