/** @format */

import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./assest/css/custom.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./assest/vendors/bootstrap/css/bootstrap.min.css";
import "./assest/vendors/animate/animate.min.css";
import "./assest/vendors/fontawesome/css/all.min.css";
import "./assest/vendors/jarallax/jarallax.css";
import "./assest/vendors/jquery-magnific-popup/jquery.magnific-popup.css";
import "./assest/vendors/nouislider/nouislider.min.css";
import "./assest/vendors/nouislider/nouislider.pips.css";
import "./assest/vendors/odometer/odometer.min.css";
import "./assest/vendors/swiper/swiper.min.css";
import "./assest/vendors/moniz-icons/style.css";
import "./assest/vendors/tiny-slider/tiny-slider.min.css";
import "./assest/vendors/reey-font/stylesheet.css";
import "./assest/vendors/owl-carousel/owl.carousel.min.css";
import "./assest/vendors/owl-carousel/owl.theme.default.min.css";
import "./assest/vendors/twentytwenty/twentytwenty.css";
import "./assest/vendors/bxslider/jquery.bxslider.css";
import "./assest/vendors/bootstrap-select/css/bootstrap-select.min.css";
import "./assest/vendors/vegas/vegas.min.css";
import "./assest/vendors/jquery-ui/jquery-ui.css";
import "./assest/vendors/timepicker/timePicker.css";

import "./assest/css/moniz.css";
import "./assest/css/moniz-responsive.css";

import "./assest/css/moniz-rtl.css";

import "./assest/css/colors/color-default.css";
import "./assest/css/modes/moniz-normal.css";

import "./assest/css/moniz-toolbar.css";
import 'font-awesome/css/font-awesome.min.css';


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
